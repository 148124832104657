import { SbComponent } from "@/components/sb-component";
import { useStoryblok } from "@/lib/storyblok/client";
import { Page } from "@/templates";
import { GetServerSideProps } from "next";
import absoluteUrl from "next-absolute-url";
import { assureTrailingSlash, isDetectDevice } from "utils";
import { Hero } from "sections";
import { getImageService, storyblokEditable } from "@hybrbase/storyblok";
import { dehydrate, QueryClient, useQuery } from "react-query";
import { getSlugStory } from "@/lib/storyblok";
import { OrganizationJsonLd, SocialProfileJsonLd } from "next-seo";

export default function Home({ queryName, preview, pageContext }) {
  const { language, resolvedUrl } = pageContext;

  const storyQuery = useQuery(
    [
      queryName,
      {
        language,
        resolvedUrl,
      },
    ],
    () => getSlugStory({ language, slug: resolvedUrl })
  );

  const dataStory = useStoryblok(storyQuery.data.story, preview);

  return (
    <>
      <SocialProfileJsonLd
        type="Organization"
        name="ONECYCLE"
        url="https://onecycle.vn/"
        sameAs={["https://www.facebook.com/onecyle???????????/"]}
      />
      <OrganizationJsonLd
        type="Corporation"
        logo="https://onecycle.vn/logo.png"
        name="ONECYCLE"
        address={{
          streetAddress: "19 Tran Ngoc Dien, Thao Dien Ward",
          addressLocality: "Ho Chi Minh",
          addressRegion: "Thu Duc",
          postalCode: "700000",
          addressCountry: "VI",
        }}
        contactPoint={[
          {
            telephone: "????",
            contactType: "customer service",
            areaServed: "VI",
            availableLanguage: ["English", "Vietnamese"],
          },
        ]}
        sameAs={["https://onecycle.com.vn/"]}
        url="https://onecycle.vn/"
      />
      <Hero
        title={dataStory?.content?.title}
        videoSrc={dataStory?.content?.video_src?.filename}
        videoPosterSrc={
          dataStory?.content?.video_poster_src &&
          getImageService(dataStory?.content?.video_poster_src?.filename)
        }
        link={{
          href: dataStory?.content?.fs_link_src?.cached_url,
          children: dataStory?.content?.fs_link_label,
        }}
        {...storyblokEditable(storyQuery.data.story.content)}
      />

      {dataStory?.content?.body?.map((blok) => {
        return <SbComponent blok={blok} key={blok._uid} />;
      })}
    </>
  );
}

Home.getLayout = function getLayout(page) {
  const {
    props: { pageContext, preview, queryName },
  } = page;
  return (
    <Page pageContext={pageContext} preview={preview} queryName={queryName}>
      {page}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  preview = true,
  locale,
  defaultLocale,
  req,
}) => {
  const queryName = "home-page";
  const { origin } = absoluteUrl(req);
  const stripUrl = "/home/";
  const language = locale || defaultLocale;

  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(
    [
      queryName,
      {
        language,
        resolvedUrl: stripUrl,
      },
    ],
    () => getSlugStory({ language, slug: stripUrl })
  );

  console.log("SERVER PREVIEW:", preview);

  return {
    props: {
      queryName,
      // Get the automatic refetches to keep our data fresh from SSR by dehydrate the client on the server
      dehydratedState: dehydrate(queryClient),
      preview,
      pageContext: {
        resolvedUrl: stripUrl,
        language: locale || defaultLocale,
        isMobile: isDetectDevice("mobile", req),
        seo: {
          defaultCanonicalUrl: assureTrailingSlash(origin + "/"),
        },
      },
    },
  };
};
